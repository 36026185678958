export const environment = {
    production: false,

    serverEndpoint:
        "https://api.e-filing-stage.ogunstatejudiciary.gov.ng/api/v1/",
    loginEndPoint: "account/login",
    confirmEmailEndPoint: "account/email-confirmed",
    resendEmailConfirmationEndPoint: "account/email-confirmed",
    forgotPasswordEndPoint: "account/forgot-password",
    validateEmail: "",
    registerEndPoint: "account/create",
};